<template>
    <div class="bg-color">
        <Navbar />
        <Header :category="'press'" :bannerTitle="bannerTitle" :breadCrumb="breadCrumb" />
        <DocumentBody :title="bannerTitle" />
        <FooterTop />
        <FooterBottom />
    </div>
</template>

<script>
    import Navbar from 'components/DefaultHome/KECC/Common/NavbarStyle'
    import Header from 'components/DefaultHome/KECC/Common/Header'
    import DocumentBody from './DocumentBody'
    import FooterTop from 'components/DefaultHome/KECC/Common/FooterTop?v=1.0'
    import FooterBottom from 'components/DefaultHome/KECC/Common/BacktopButton'

    export default {
        name: 'Document',
        components: {
            Navbar,
            Header,
            DocumentBody,
            FooterTop,
            FooterBottom,
        },
        metaInfo() {
            return {
                title: this.$t("navbar.menu_3_4"),
            }
        },
        data() {
            return {
                bannerTitle: "文件下載",
                breadCrumb: [{
                    name: this.$t('navbar.menu_3')
                }],
                aboutNavTitle: [
                    '最新消息',
                    '展館照片',
                    '文件下載',
                ],
                aboutNavContent: [
                    '5G wireless technology is meant to deliv',
                    '5G wireless technology is meant to deliv',
                    '5G wireless technology is meant to deliv',
                ]
            }
        },
    }
</script>

<style>
    .bg-color {
        background-color: #eff4f5;
    }
</style>